@import '../../styles/gifts/sass/basics';

.price-range-filter {
  margin-bottom: 32px;
  min-width: 250px;

  &__header {
    color: $gray-1000;
    font-size: 15px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: bold;
    text-transform: uppercase;
  }

  &__values {
    font-weight: $font-weight-regular;
  }

  :global {
    .rc-slider {
      .rc-slider-track,
      .rc-slider-handle {
        background: $pink-1200;
      }

      .rc-slider-handle {
        border-color: $pink-1200;
        width: 12px;
        height: 12px;

        &:focus {
          box-shadow: 0 0 0 5px $pink-1200;
        }

        &:active {
          border-color: $pink-1200;
        }
      }

      .rc-slider-handle-1 {
        margin-left: -1px;
      }

      .rc-slider-handle-2 {
        margin-left: -11px;
      }

      .rc-slider-dot-active {
        border-color: $pink-1200;
      }

      .rc-slider-dot,
      .rc-slider-mark-text {
        display: none;
      }
    }
  }
}
