@import '../../../../styles/gifts/sass/basics';
@value gift-item, gift-item__content, gift-item__description from '../../../GiftItem/index.module.scss';

.bb-catalog-with-filters {
  //max-width: 1920px;
  background-color: $white;
  margin: 36px auto;
  &__menu {
    display: flex;
    flex-direction: column;
  }

  &__gifts-grid {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 24px;
    row-gap: 64px;
    &::after {
      content: "";
      flex: auto;
    }
  }

  .gift-item {
    .gift-item__description {
      display: none;
    }

    .gift-item__content {
      text-align: center;
    }
  }

  :global(.container) {
    position: relative;
    z-index: 2;
  }

  @media screen and (min-width: 360px) {
    .gift-item {
      width: calc(50% - 12px);
    }
  }

  @include media-breakpoint-up(md) {
    margin: 48px auto;

    &__menu {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .gift-item {
      width: calc(33.33% - 16px);
    }
  }
}